<template>
    <div class="content" style="background: #f1f1f1;" :class="{'hui': resData.ishui == 'Y'}">
       <Header></Header>
       <div class="top-img">
            <div class="banner-img" v-for="(item,index) in banner" :key="index">
                <img :src="item.img" alt="">
            </div>
        </div>
        <div class="content-1200 list-div">
           <div class="list-div-left">
                <img src="static/jr/listnan.png" alt="" style="width: 100%;">
                <ul class="tabs" v-for="(list,i) in columnList" :key="i">
                    <li class="li-tab" v-for="(item,index) in list.stypes" :key="index"
                    @click="toggleTabs(item.id),hrefUrlList(item.id,item.pid)"
                    :class="{active:item.id==id2}"><p>{{item.title}}</p>
                    </li>
                </ul>
            </div>
            <div class="list-div-right">
                <div class="list-div-right-title">
                    <p style="font-size:22px;color: #333;margin-bottom: 10px;">{{content.title}}</p>
                    <p style="text-align: right;color:#666;font-size:12px;margin-bottom: 10px;">
                        <!-- <span>时间：{{content.ctime}}</span>
                        <span style="padding-left:20px;">来源：{{details.pname}}</span> -->
                        <span>来源：{{content.typename}}</span>
                        <span style="padding-left:20px;">创建时间：{{content.ctime}}</span>
                        <span style="padding-left:20px;">创建人：{{content.source}}</span>
                        <span style="padding-left:20px;">点击量：{{content.count}}</span>
                    </p>
                </div>
                <p class="details-content" v-html="content.content"></p>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Footer from './components/footer'
import Header from './components/subpageHeader'
import JrApi from '../../lvya-common/src/api/eos/jr'
import DoUrl from '@/lvya-common/src/utils/DoUrl'
import Login from '@/lvya-common/src/api/eos/Login'
export default {
    components: {
        Footer,
        Header
    },
    data() {
        return {
            tabsParam: ['学校简介', '治理结构'],
            nowIndex: 0,
            banner: '',
            columnList: [],
            list: [],
            details: '',
            content: '',
            id: '',
            id2: '',
            resData: '',
        }
    },
    async created() {
        this.getId()
        this.ColumnList()
        this.List()
        this.HF()
        if (Cookies.get('EOSToken')) {
            // console.log(Cookies.get('EOSToken'))
            this.Details()
        } else if (location.href.includes('trustCode=')) {
            let trustCode = DoUrl.getSearchByName('trustCode', location.href)
            const loginInfo = await Login.getToken({ authorizationCode: trustCode, schoolId: 38 })
            Cookies.set('EOSToken', loginInfo.token)
            this.Details()
        } else {
            this.Details()
        }
    },
    // 监听路由参数
    watch: {
        '$route'(to, from) {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.List()
            this.ColumnList()
        //    console.log(this.$route.query.pid, this.$route.query.id)
        }
    },
    methods: {
        toggleTabs: function(id) {
            this.id = id
            this.List()
            // console.log(this.id2)
        },
        // 跳转二级页面
        hrefUrlList(id, pid) {
            // debugger
            this.$router.push({ path: '/list', query: { id, pid }})
        },
        getId() {
            this.pid = this.$route.query.pid
            this.id = this.$route.query.id
            this.id2 = this.$route.query.id2
            // console.log(this.pid, this.id)
        },
         // banner图
        async Banner() {
            const resData = await JrApi.Banner({ typeid: this.pid })
            this.banner = resData
            // console.log(this.banner)
        },
        // 左侧栏目导航
        async ColumnList() {
            const resData = await JrApi.Yycenter({ pid: this.pid })
            this.columnList = resData
            // console.log(this.columnList)
        },
        // 右侧列表
        async List() {
            const resData = await JrApi.Xxxw({ pid: this.pid, typeid: this.id })
            this.list = resData
            // console.log(this.list)
        },
        async Details() {
            const params = {
                id: this.id
            }
            const resData = await JrApi.Details(params) // /{id}/getArtInfo
            resData.info.content = this.escape2Html(resData.info.content)
            this.details = resData
            this.content = resData.info
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
        },
        escape2Html(str) {
            const arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' }
            return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) { return arrEntities[t] })
        }
    },
    mounted() {
        this.Banner()
    }
}
</script>

<style scope>
@font-face {
 font-family: 'qigong';
 src: url('../../font/qigong.TTF');
}
p{
    margin-block-start: 0em;
    margin-block-end: 0em;
}
ul{
    margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 0px;
}
.content {
  width: 100%;
}
.hui{
  -webkit-filter:grayscale(100%)
}
.content-1200{
    width: 1200px;
    min-height: 300px;
    margin: 20px auto;
}
.list-div{
    min-height: 300px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 3px;
    display: flex;
}
.list-div-left{
    width: 230px;
}
.list-div-right{
    width: 80%;
    flex: 1;
    padding: 0 15px;
    box-sizing: border-box;
}
.list-div-right-title{
    text-align: center;
    border-bottom: 1px dashed #ddd;
}
.top-img{
  width: 100%;
  height: 410px;
  min-width: 1200px;
}
.banner-img {
    width: 100%;
    height: 410px;
    min-width: 1200px;
    background: url('../../../public/static/jr/banner2.png') center top no-repeat;
    /* background-size: 100% 100%; */
}
.top-img img{
    width: 100%;
    height: 100%;
}
.active{
    background: #9d2228;
    color: #fff;
}
.tabs{
    width: 100%;
    font:0;
    padding:0;
    background: #eee;
    margin-top: -4px;
}
.li-tab{
    width: 100%;
    line-height: 50px;
    /* height: 100%; */
    display:inline-block;
    text-align: center;
    cursor: pointer;
}
.divTab{
    width: 100%;
    min-height: 300px;
}
.divTab-top{
    border-bottom: 1px solid #eee;
}
.red {
    width: 4px;
    height: 35px;
    background: #ba291c;
    display: inline-block;
}
.divTab-ul{
    width: 100%;
    margin-top: 10px;
}
.divTab-ul li{
    display: flex;
    justify-content: space-between;
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #555;
}
.divTab-ul li:hover{
    color: #9d2228;
    cursor: pointer;
}
.divTab-ul li .time{
    color: #777;
}
.divTab-ul li .ell{
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
    background: #bd2b17;
}
.el-pagination{
    margin-top: 10px;
    padding-left: 0px;
}
.details-content{
    font-size: 14px;
    line-height: 35px;
}
.details-content table{
    width:100%;
}
.details-content img{
    max-width: 100%;
    width: auto;
    height: auto;
}
</style>
